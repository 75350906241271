.add-user__wrapper {
    min-height: 100vh;
}

.add-user__action-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-user__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-user__header h2 {
    color: #424242;
}

.add-user__header button {
    margin: 12px;
}

.add-user__header .save{
    background-color: #ffc400;
    border: solid 1px #ffc400;
    box-shadow: 1px 2px 5px #ffc400;
    animation: saveBlink 1s infinite alternate-reverse;
}

.add-user__main {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.add-user__stream {
    overflow: visible;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 20px 0;
}

.add-user__stream-wrapper{
    max-width: 600px;
}

.add-user__stream > div {
    overflow: visible;
}

.add-user__wrapper hr {
    margin-top: 50px;
    background-color: #e0e0e0;
    height: 1px;
}

.add-user__streams{
    width: 100%;
    text-align: center;
}

.add-user__streams-title{
    display: block;
    color: #424242;
    padding: 10px 0 30px 0;
    font-size: 1.5em;
}

.add-user__streams td{
    padding: 20px;
}

.add-user__streams-actions{
    cursor: pointer;
    transition: .3s;
}

.add-user__streams-actions:hover{
    transform: scale(1.05);
}

.add-user__error{
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.add-user__error b{
    color: red;
}

@keyframes saveBlink {
    100%{
        background-color: #ffa500;
        border: solid 1px #ffa500;
        box-shadow: 1px 2px 5px #ffa500;
    }
}
