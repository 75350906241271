.input__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.input__wrapper > label {
    color: #080658;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 4px;
}

.input__required {
    color: red;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.input__field {
    padding: 10px 20px;
    border: solid 1px #b1b1b1;
    border-radius: 3px;
    outline: none;
    text-align: center;
    transition: .3s;
    flex: 1;
    font-size: .9em;
}

.input__field:hover{
    border: solid 1px #494949;
}

.input__field.error {
    border: 1px solid #FF0000;
}

.input__wrapper.disabled > label {
    color: #8383AB;
}

.input__field-wrapper {
    position: relative;
}
