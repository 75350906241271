.login {
    width: 100%;
    min-height: 100vh;
    background-color: var(--main-color);
    display: flex;
}

.login__left {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login__logo {
    width: 90%;
    max-width: 350px;
}

.login__form {
    width: 60%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login__form h2 {
    padding: 15px 0;
}

.login__error {
    color: red;
}

.login form {
    width: 100%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login__fields {
    width: 50%;
    margin: 5px auto;
    display: flex;
    gap: 5px;
    flex-direction: column;
    padding: 12px;
}

.login__fields button {
    margin: 12px 0px;
}

@media (max-width: 480px) {
    .login {
        flex-direction: column;
        align-items: center;
    }

    .login__left {
        flex: 1;
        width: 90%;
    }

    .login__form {
        flex: 3;
        width: 100%;
    }
}
