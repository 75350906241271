.error_block {
    width: 100%;
    min-height: 100vh;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.error__wrapper{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 35px;
}

.error_block h1{
    color: white;
    font-size: 3em;
    text-align: center;
}

.error_block b{
    color: white;
    text-align: center;
}

.error__menu{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.error__menu a{
    color: white;
    padding: 10px 20px;
    background-color: var(--main-color-dark);
    border-radius: 5px;
    transition: .3s;
}

.error__menu a:hover{
    background-color: var(--main-color-dark-hover);
}
