.input-copy__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.input-copy__wrapper > label {
    display: block;
    font-size: .9em;
    font-weight: 700;
    color: #787878;
    padding-left: 6px;
}

.input-copy__field {
    padding: 10px 20px;
    border: solid 1px #b1b1b1;
    border-radius: 3px 0 0 3px;
    outline: none;
    text-align: center;
    transition: .3s;
    flex: 1;
    font-size: .9em;
}

.input-copy__field:hover{
    border: solid 1px #494949;
}

.input-copy__field.error {
    border: 1px solid #FF0000;
}

.input-copy__field-wrapper {
    position: relative;
}

.input-copy__fields-wrapper{
    display: flex;
    padding: 6px 0px;
}

.input-copy__button{
    background-color: #838383;
    color: white;
    outline: none;
    border: solid 1px #838383;
    border-radius: 0 3px 3px 0;
    padding: 0 5px;
    min-width: 60px;
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s;
}

.input-copy__button:hover{
    background-color: white;
    color: var(--main-color);
}
