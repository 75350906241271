.dashboard__content {
    flex: 1;
    overflow: visible;
}

.dashboard__wrapper{
    max-width: 1024px;
    min-height: calc(100vh - 40px);
    margin: 0 auto;
    padding: 15px 50px;
    overflow: hidden;
}
