.apps__item {
    outline: solid 1px #d7d7d7;
}

.apps__item td {
    font-size: .8em;
}

.apps__item-action {
    cursor: pointer;
    transition: .3s;
}

.apps__item-action:hover {
    transform: scale(1.05);
}
