.restreams{
    width: 100%;
    padding: 25px 0;
}

.restreams b{
    color: #787878;
}

.restreams ul{
    margin-top: 10px;
}
