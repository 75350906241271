.users__item{
    outline: solid 1px #d7d7d7;
}

.users__item td{
    font-size: .8em;
}

.users__item td a{
    color: black !important;
}

.users__item-action{
    cursor: pointer;
    transition: .3s;
}

.users__item-action:hover{
    transform: scale(1.05);
}
