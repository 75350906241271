.restream__modal{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 35%;
    min-height: 30vh;
}

.restream__modal-close{
    align-self: flex-end;
    cursor: pointer;
}

.restream__modal-close:hover{
    color: #3d3d3d;
}

.restream__modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 800;
}

.restream__modal-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    min-height: 40vh;
    overflow: revert;
}

.restream__create-step{
    width: 100%;
    overflow: visible;
}

.restream__create-step.select > div{
    overflow: visible;
}

.restream__modal-form button{
    width: 100%;
}

.restream__select-item{
    display: flex;
    align-items: center;
}

.restream__select-item span{
   flex: 1;
    text-align: center;
}

.restream__error{
    color: red;
    padding-bottom: 10px;
}
