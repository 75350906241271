.navigation {
    min-height: 100vh;
    width: 250px;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.navigation__logo{
    margin: 25px auto 0;
    max-width: 200px;
    width: 80%;
}

.navigation ul{
    margin-top: 75px;
    width: 100%;
}

.navigation ul li{
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    color: white;
}

.navigation ul li a{
    color: white;
    display: block;
    background-color: var(--main-color);
    font-size: 1em;
    font-weight: 600;
    padding: 10px;
    transition: .3s;
    cursor: pointer;
}

.navigation ul li a:hover{
    background-color: var(--main-color-hover);
}

.navigation ul li a.active{
    color: var(--main-color-active);
    background-color: white;
}

.navigation ul li a.active:hover{
    background-color: #efefef;
}

@media (max-width: 1280px) {

}

@media (max-width: 980px) {
    .navigation{
        width: 150px;
    }
}

@media (max-width: 720px) {
    .navigation{
        width: 100px;
    }
}
