@keyframes ldio-lu86vq2gdwb {
    0% {
        top: 96px;
        left: 96px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 18px;
        left: 18px;
        width: 156px;
        height: 156px;
        opacity: 0;
    }
}

.ldio-lu86vq2gdwb div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-lu86vq2gdwb 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio-lu86vq2gdwb div:nth-child(1) {
    border-color: var(--main-color);
    animation-delay: 0s;
}

.ldio-lu86vq2gdwb div:nth-child(2) {
    border-color: #bdbdbd;
    animation-delay: -0.5s;
}

.loadingio-spinner-ripple-10rbk3cs9y2 {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
}

.ldio-lu86vq2gdwb {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-lu86vq2gdwb div {
    box-sizing: content-box;
}
