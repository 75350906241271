.dashboard {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.dashboard__loading, .dashboard__error{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.dashboard__error{
    color: red;
    flex-direction: column;
    gap: 15px;
}

.dashboard__activity{
    align-self: center;
}

.panel {
    flex: 1;
    max-width: 1024px;
    margin: 0 auto;
    padding: 15px 50px;
    display: flex;
    flex-direction: column;
}

.panel__header {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panel__header b{
    font-size: 1.7em;
    color: #424242;
}

.panel__logout {
    color: #a4a4a4;
    cursor: pointer;
    transition: .3s;
}

.panel__logout:hover {
    color: #4e4e4e;
}

.panel__wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 1280px) {
    .panel__wrapper {
        justify-content: center;
    }
}

@media (max-width: 980px) {

}

@media (max-width: 720px) {
    .login > form {
        width: 100%;
    }

    .panel {
        padding: 5px;
    }

    .panel__wrapper{
        width: 100%;
    }
}
