@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root{
    --main-color: #901731; /* #901731 - sw, #0fb2db - lt */
    --main-color-hover: #c41f43; /* #c41f43 - sw, #00a5cf - lt */
    --main-color-active: #c41f43; /* #c41f43 - sw, #00a5cf - lt */
    --main-color-dark: #c41f43; /* #c41f43 - sw, #00a5cf - lt */
    --main-color-dark-hover: #c41f43; /* #c41f43 - sw, #00a5cf - lt */
}

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style-type: none;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
}

html{
    min-width: 320px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #818181 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #818181;
    border-radius: 10px;
    border: 3px solid #ffffff;
}
