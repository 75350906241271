.servers__wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.servers__wrapper.error b{
    color: red;
}

.servers__wrapper.error button{
    margin: 15px;
}

.servers__table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.servers__table thead{
    background-color: #f8f8f8;
    border-radius: 5px;
    overflow: auto;
    font-size: .9em;
    color: #3d3d3d;
}

.servers__table th, .servers__table td {
    padding: 10px;
}

.servers__footer{
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: right;
}

.servers__footer button{
    margin: 12px;
}

.servers__add{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.servers__add button{
    margin: 12px;
}

.servers__add-header{
    display: flex;
    justify-content: center;
    align-items: center;
}


.servers__add-wrapper{
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.servers__add-header .error{
    color: red;
}
