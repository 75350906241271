.input-pass__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.input-pass__wrapper > label {
    color: #080658;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 4px;
}

.input-pass__required {
    color: red;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.input-pass__field {
    padding: 10px 20px;
    border: solid 1px #b1b1b1;
    border-radius: 3px;
    outline: none;
    text-align: center;
    transition: .3s;
    flex: 1 1;
    font-size: .9em;
}

.input-pass__field:hover{
    border: solid 1px #494949;
}

.input-pass__field.error {
    border: 1px solid #FF0000;
}

.input-pass__field-wrapper {
    position: relative;
}

.input-pass__toggle {
    position: absolute;
    bottom: 4px;
    right: 20px;
    cursor: pointer;
}

.input-pass__toggle > * {
    font-size: 16px;
    color: #93939399;
    transition: .3s;
}

.input-pass__toggle > *:hover {
    color: rgba(94, 94, 94, 0.6);
}
