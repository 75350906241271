.player {
    min-width: 384px;
    border-radius: 5px;
    position: relative;
}

.player__fake {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    cursor: pointer;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "";
    background-image: url('video_play.png');
    background-repeat: no-repeat;
    background-size: 46px;
    background-position: 55% calc(50% - 0px);
    border: none !important;
    box-shadow: none !important;
}

.video-js .vjs-big-play-button {
    border: none;
    background-color: var(--main-color);
    cursor: pointer;
    transition: .3s;
}

.video-js .vjs-big-play-button:hover {
    background-color: #7a0f26;
    transform: scale(1.01);
}

.vjs-waiting .vjs-loading-spinner {
    overflow: hidden;
}
