.restream__remove-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 900;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.restream__remove-modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 800;
}

.restream__remove-buttons{
    margin-top: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.restream__modal-msg{
    display: flex;
    flex-direction: column;
    text-align: center;
}
