.primary-button{
    background-color: #838383;
    color: white;
    outline: none;
    border: solid 1px #838383;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 1px 2px 5px #838383;
    cursor: pointer;
    transition: .3s;
}

.primary-button:hover{
    background-color: white;
    color: var(--main-color);
    box-shadow: 1px 2px 10px #838383;
}

.primary-button.white{
    background-color: white;
    color: var(--main-color);
    outline: none;
    border: solid 1px #838383;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 1px 2px 5px #838383;
    cursor: pointer;
    transition: .3s;
}

.primary-button.white:hover{
    background-color: #838383;
    color: white;
    box-shadow: 1px 2px 10px #838383;
}
