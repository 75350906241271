.publish-stream__item{
    max-width: 410px;
}

.publish-stream__item-settings{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
}

.publish-stream__item-settings > .input-copy__wrapper {
    flex: 1;
    min-width: 150px;
}

.panel__output-status{
    display: block;
    font-size: .9em;
    font-weight: 700;
    color: #787878;
    padding-left: 6px;
}

.panel__output-info{
    margin: 6px 0;
    display: flex;
    gap: 50px;
    align-items: center;
    color: #424242;
    background-color: #e3e3e3;
    padding: 7px;
    border-radius: 3px;
}

.panel__output-info .status{
    width: 20px;
    height: 20px;
    background-color: #CA2B2A;
    border-radius: 50%;
    margin-left: 15px;
    outline: none;
    border: none;
}

.panel__output-info .status.paused{
    width: 20px;
    height: 20px;
    background-color: #a9a9a9;
    border-radius: 50%;
    margin-left: 15px;
    outline: none;
    border: none;
}

.panel__output-info .status.pending{
    width: 20px;
    height: 20px;
    background-color: #ffa600;
    border-radius: 50%;
    margin-left: 15px;
    outline: none;
    border: none;
}

.panel__output-info .status.online{
    width: 20px;
    height: 20px;
    background-color: #2ACA4E;
    border-radius: 50%;
    margin-left: 15px;
    outline: none;
    border: none;
}
