.users__wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.users__wrapper.error b{
    color: red;
}

.users__wrapper.error button{
    margin: 15px;
}

.users__table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.users__table thead{
    background-color: #f8f8f8;
    border-radius: 5px;
    overflow: auto;
    font-size: .9em;
    color: #3d3d3d;
}

.users__table th, .users__table td {
    padding: 10px;
}

.users__footer{
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: right;
}

.users__footer button{
    margin: 12px;
}
