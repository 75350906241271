.switch__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    text-align: left;
}

.switch__wrapper > span {
    color: #080658;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 4px;
}
