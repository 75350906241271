.restream{
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.restream__type{
    flex: 2;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.restream__type img{
    height: 45px;
}

.restream__type b{
    color: #3f3f3f;
}

.restream__fields{
    flex: 4;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.restream__fields > .input__wrapper{
    flex: 1;
}

.restream__empty{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: 600;
    border-bottom: solid 2px black;
    padding: 2px;
    margin-top: 25px;
    cursor: pointer;
    transition: .3s;
}

.restream__empty:hover{
    color: #3d3d3d;
}

.restream__actions{
    padding: 0 10px;
}

.restream__control{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.restream__control button{
    outline: none;
    border: none;
    background-color: transparent;
    transition: .3s;
    cursor: pointer;
}

.restream__control button.start{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #d5d5d5;
    cursor: auto;
}

.restream__control button.start:hover{
    border-color: transparent transparent transparent #d5d5d5;
}

.restream__control button.start.active{
    border-color: transparent transparent transparent #838383;
}

.restream__control button.start.active:hover{
    border-color: transparent transparent transparent #424242 !important;
    cursor: pointer;
}

.restream__control button.stop{
    width: 20px;
    height: 20px;
    background-color: #d5d5d5;
}

.restream__control button.stop:hover{
    background-color: #d5d5d5 !important;
    cursor: auto;
}

.restream__control button.stop.active{
    background-color: #838383;
}

.restream__control button.stop.active:hover{
    background-color: #424242 !important;
    cursor: pointer;
}

.restream__control button.status{
    width: 20px;
    height: 20px;
    background-color: #CA2B2A;
    border-radius: 50%;
    margin-left: 15px;
}

.restream__control button.status.started{
    background-color: #2ACA4E;
}

.restream__control button.status.starting{
    background-color: #8ec200;
    animation: statusStarting 1.5s infinite ease-in-out alternate;
}

.restream__control button.status.stopping{
    background-color: #ffa600;
    animation: statusStopping 1.5s infinite ease-in-out alternate;
}

.restream__control button.status.stopped{
    background-color: #CA2B2A;
}

.restream .actions{
    color: #424242;
    font-size: 1.7em;
    cursor: pointer;
    transition: .3s;
}

.restream .actions:hover{
    color: #606060;
}



@keyframes statusStarting {
    50%{
        background-color: #8ec200;
    }
    100%{
        background-color: #b7ed73;
    }
}

@keyframes statusStopping {
    50%{
        background-color: #ffa600;
    }
    100%{
        background-color: #ffd280;
    }
}
